import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../components/layout'

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <section>
          <h1>Not found</h1>
          <h3 className="mt">
            <Link to="/">Visit the homepage</Link>
          </h3>
        </section>
      </Layout>
    )
  }
}

export default NotFoundPage
